@import "../variables/colors.scss";

footer {
  background: $dark;
  color: white;

  .footer_top, .footer_bottom {
    background-color: rgb(26, 26, 26);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .row {
      background-color: rgb(26, 26, 26);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }

  .footer_middle {
    &.row {
      background-color: $grey;
      padding-top: 42px;
      padding-bottom: 42px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }

  ul {
    list-style-type: square;
    padding: 20px;
  }

  .footer_nav, .footer_cat {
    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      list-style: none;
      margin-bottom: 0;
    }
  }

  .logo_footer {
    padding-bottom: 28px;
    position: relative;
    text-align: center;
    margin: 44px auto;

    &::after {
      content: '';
      height: 1px;
      background-color: rgb(127, 127, 127);
      position: absolute;
      width: 72px;
      bottom: 0;
      left: calc(50% - 36px);
    }
  }

  img {
    max-width: 100%;
    vertical-align: bottom;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
  }

  .footer_social_links {
    p {
      color: rgb(255, 255, 255);
      font-size: 14px;
      text-align: center;
      font-weight: 400;
    }

    ul.social_links {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      list-style: none;
      margin-bottom: 0;

      li {
        padding: 0 10px;

        a {
          color: rgb(255, 255, 255);
          font-size: 22px;
        }
      }
    }
  }

  .footer_bottom {
    .links {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      p {
        text-align: center;
        color: rgb(255, 255, 255);
        padding: 12px;
        margin-bottom: 0;

        a:hover {
          color: rgb(8, 99, 117);
        }
      }
    }
  }


  .footer_cat {
    border-bottom: solid 2px rgb(127, 127, 127);
    padding-bottom: 42px;
  }

  .footer_nav ul a:hover, .footer_cat ul a:hover {
    color: $mint;
  }

  p, a {
    color: rgb(255, 255, 255);
  }

  .footer-partners-el {
    width: 15%;
    max-height: 150px;
    padding: 15px;
    min-width: 180px;

    img {
      height: auto;
      max-height: 69px;
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }
}


@media only screen and (min-width: 1140px) {
  .nav ul a, .footer_nav ul a, .footer_cat ul a p {
    font-size: 14px;
  }

  .footer_nav ul a, .footer_cat ul a {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
  }
}

.footer_cat ul a p {
  font-size: 14px;
}

@media only screen and (min-width: 1140px) {
  .footer_top.row, .footer_bottom.row {
    height: 100px;
  }
}

@media only screen and (min-width: 1140px) {
  .footer_bottom .links {
    p, a {
      font-size: 12px;
    }
  }
  .footer_bottom .links {
    p, a {
      text-decoration: none;
      font-weight: 400;
    }
  }

}


@media only screen and (max-width: 768px) {
  .footer_cat {
    display: none;
  }
  .footer_bottom .links, .footer_nav ul {
    flex-direction: column !important;
  }

}
